/**
 * Define to namespace app
 **/
Application = {
    Controller: {},
    Request: {},
    Helper: {}
};

/**
 * Returns a complete URL given a relative path.
 *
 * @param string url URL relative server
 * @return string URL complet
 */
Application.build_url = function(url) {
    if (! Application.BASE_URL || ! Application.BASE_URL.match(/^http/)) {
        Application.BASE_URL = $('meta[name=base_url]').attr('content');
    }

    return Application.BASE_URL + url;
}

jQuery(document).ready(function($)
{
    // Code controller
    var controller = $('meta[name=controller]').attr('content');
    var action = $('meta[name=action]').attr('content');

    Application.Controller[controller] &&
    Application.Controller[controller]['init'] &&
    Application.Controller[controller]['init'].call();

    Application.Controller[controller] &&
    Application.Controller[controller][action] &&
    Application.Controller[controller][action].call();

    // Target blank
    $("a[rel~='external']").on('click', function() {
        window.open($(this).attr('href'));

        return false;
    });

    // Tooltips
    $('body a').tooltip();

    // Filter advance
    function filterAdvance()
    {
        $('.filter_advanced').show();

    	var close = '.close, .cancel';
	    var filter = '.filter_advanced';
	    var width_balloon = $(filter).outerWidth();
	    var margin_left = width_balloon / 2;

	    $(filter).css('margin-left', -margin_left);

	    $(close).click(function() {
	    	$(this).parent().parent(filter).hide();
	    });
    }

    $('.trigger_filter_advanced').on('click', filterAdvance);

    // Remove tags to filter
    $('.tags li a').on('click', function() {
        $(this).next('li').remove();
    });

    // Per page
    $('select[name=per_page]').on('change', function() {
        $(this).closest('form').submit();
    });

    // Remove entity
    $('table.table .icon-trash').on('click', function() {
        var message = $(this).data('details');
        var formWidget = $(this).parent().find('#form').html();

        if (confirm(message)) {
            $('<form/>', { method: 'post', action: $(this).attr('href') })
                .html(formWidget)
                .append('<input type="hidden" name="_method" value="DELETE" />')
                .appendTo('body')
                .submit();
        }

        return false;
    });

    // Order ASC
    $('table.table th.order_asc').on('click', function() {
        $(this).removeClass('order_asc').addClass('order_desc');
    });

    // Order DESC
    $('table.table th.order_desc').on('click', function() {
        $(this).removeClass('order_desc').addClass('order_asc');
    });

    // Flash message
    $('body').on('click', '.fechar_feedback', function() {
        $feedback.remove();
    });

    // Feedback message
    $feedback = $('.feedback');

    if ($feedback.length > 0) {
        $feedback.show();
        if (!$feedback.find('.mensagem').hasClass('error')) {
            Application.UI.removeMessage();
        }
    }

    // Focus
    $(':text:visible:not(.prevent_focus):first').focus();

    // Validation error
    $('ul.validation_errors').each(function() {
        $(this).closest('div').addClass('erro');
    });

    // Require label
    $('form label.required').each(function() {
        $(this).append(' *');
    });

    // Tooltip label
    $('form label').each(function() {
        var tooltip = $(this).data('tooltip');

        if (tooltip) {
            tooltip = ' (' + tooltip + ')';

            $('<span/>', { class: 'muted'}).text(tooltip).appendTo($(this));
        }
    });

    // Datepicker
    if ($.fn.datepicker) {
        $('.datepicker input').datepicker({
            dateFormat: 'dd/mm/yy',
            dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
            dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
            dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
            monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
            monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
            nextText: 'Próximo',
            prevText: 'Anterior'
        });
    }

    // Remove file
    $('form .remove_entity_file').on('click', function() {
        var message = $(this).data('details');

        if (! confirm(message)) {
            return false;
        }

        $('<form/>', { method: 'post', action: $(this).attr('href') }).appendTo('body').submit();

        return false;
    });

    // Sortable
    $.fn.sortable && $('table.sortable tbody').sortable({
        helper: function(e, ui) {
            ui.children().each(function() {
                $(this).width($(this).width());
            });
            return ui;
        },
        axis: 'y',
        handle: '.icon-move',
        revert : true,
        update: function(e, ui) {
            var $table = $(e.target).closest('table');
            $.ajax({
                type: 'post',
                url: $table.find('.icon-move:first').attr('href'),
                data: $('table.sortable tbody').sortable('serialize')
            });
        }
    }).disableSelection();

    // Bool
    $('table a.toggle_boolean_column').on('click', function() {
        var $link = $(this);
        var column = $link.data('column');
        var value = $link.parent().hasClass('yes') ? 0 : 1;
        $.ajax({
            type: 'post',
            url: $link.attr('href'),
            data: 'column=' + column + '&value=' + value,
            success: function(data) {
                if (!value) {
                    $link.text('Não');
                    $link.parent().addClass('no');
                    $link.parent().removeClass('yes');
                    $link.addClass('red');
                    $link.removeClass('green');
                } else {
                    $link.text('Sim');
                    $link.parent().addClass('yes');
                    $link.parent().removeClass('no');
                    $link.addClass('green');
                    $link.removeClass('red');
                }
            }
        });

        return false;
    });

    // Value
    if ($('.discount').length) {
        $('.discount').priceFormat({
            prefix: '% ',
            limit: 4,
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
    }

    if ($('.decimal').length) {
        $('.decimal').priceFormat({
            prefix: '',
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
    }

    if ($('.value').length) {
        $('.value').priceFormat({
            prefix: 'R$ ',
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
    }

    // Mask util
    if ($.fn.mask) {
        $('input.mask-date').mask('99/99/9999');
        $('input.mask-datetime').mask('99/99/9999 99:99');
        $('input.mask-zip-code').mask('99999-999');
        $('input.mask-document').mask('999.999.999-99');
        $('input.mask-federal').mask('99.999.999/9999-99');
        $('input.mask-phone').focusout(function() {
			var phone, element;
			element = $(this);
			element.unmask();
			phone = element.val().replace(/\D/g, '');
			if (phone.length > 10) {
				element.mask("(99) 99999-999?9");
			} else {
				element.mask("(99) 9999-9999?9");
			}
		}).trigger('focusout');
    }

    // Select picker
    $('.selectpicker').selectpicker();

    // Select all records
    $('#select-list-all').on('click', function() {
        if (this.checked) {
            $('.checkbox_records').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_records').each(function() {
                this.checked = false;
            });
        }
    });

    // Select remove records
    $('#select-list-remove').on('click', function() {
        if (this.checked) {
            $('.checkbox_removes').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_removes').each(function() {
                this.checked = false;
            });
        }
    });

    // Select all records
    $('#select-list-all-characteristics').on('click', function() {
        if (this.checked) {
            $('.checkbox_records_characteristics').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_records_characteristics').each(function() {
                this.checked = false;
            });
        }
    });

    // Select all records
    $('#select-list-all-sustainabilities').on('click', function() {
        if (this.checked) {
            $('.checkbox_records_sustainabilities').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_records_sustainabilities').each(function() {
                this.checked = false;
            });
        }
    });

    // Select remove records
    $('#select-list-remove-characteristics').on('click', function() {
        if (this.checked) {
            $('.checkbox_removes_characteristics').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_removes_characteristics').each(function() {
                this.checked = false;
            });
        }
    });

    // Select remove records
    $('#select-list-remove-sustainabilities').on('click', function() {
        if (this.checked) {
            $('.checkbox_removes_sustainabilities').each(function() {
                this.checked = true;
            });
        } else {
            $('.checkbox_removes_sustainabilities').each(function() {
                this.checked = false;
            });
        }
    });

    // Remove records all
    $('.remove-list').on('click', function() {
        if (confirm($(this).data('details'))) {
            $('form#records-list').attr('action', $(this).data('url'));
            $('form#records-list').submit();

            return true;
        }

        return false;
    });

    // Update records all
    $('.update-list').on('click', function() {
        $('form#records-list').attr('action', $(this).data('url'));
        $('form#records-list').submit();

        return true;
    });

    // Update records all
    $('.update-list-characteristics').on('click', function() {
        $('#form-remove-characteristics').attr('action', $(this).data('action'));
        $('#form-remove-characteristics').submit();

        return true;
    });

    // Update records all
    $('.update-list-sustainabilities').on('click', function() {
        $('#form-remove-sustainabilities').attr('action', $(this).data('action'));
        $('#form-remove-sustainabilities').submit();

        return true;
    });

    // Update records all
    $('.update-list-recreation, .update-list-feature').on('click', function() {
        $('#form-remove').attr('action', $(this).data('action'));
        $('#form-remove').submit();

        return true;
    });

    $('a.add-line').on('click', function() {
        var parentDiv = $(this).parent().parent().parent();

        if (parentDiv.length) {
            parentDiv.find('tbody tr:first').clone(true).appendTo(parentDiv.find('tbody tr:last').parent()).find(':input').val('');
        } else {
            $('tbody tr:first').clone(true).appendTo($('tbody tr:last').parent()).find(':input').val('');
        }
    });

    $('a.remove-line').on('click', function() {
        var parentDiv = $(this).parent().parent().parent().parent();

        if (parentDiv.length) {
            if (parentDiv.find('tbody tr').length > 1) {
                $(this).closest('tr').remove();
            } else {
                $(this).closest('tr').find(':input').val('');
            }
        } else {
            if ($('tbody tr').length > 1) {
                $(this).closest('tr').remove();
            } else {
                $(this).closest('tr').find(':input').val('');
            }
        }
    });

    // Color picker
    $('.js-color').colorPicker();

    // Gallery
    if (action == 'editGallery' || action == 'editPlants' || action == 'editProgress' || action == 'editBanners' || action == 'editRecreation' || action == 'editFeature') {
        Application.Helper.Gallery.init();
    }

    // Control request ajax
    $(document).ajaxStart(function() {
        Application.UI.showActivityStatus();
    }).ajaxSuccess(function () {
        Application.UI.showMessage({ type: 'success', message: 'Operação realizada com sucesso.' });
    }).ajaxError(function() {
        Application.UI.showMessage({ type: 'error', message: 'Erro inesperado comunicação com o servidor.' });
    });
});
